enifed("ember-template-compiler/lib/plugins/assert-local-variable-shadowing-helper-invocation", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertLocalVariableShadowingHelperInvocation;

  function assertLocalVariableShadowingHelperInvocation(env) {
    var moduleName = env.meta.moduleName;
    var locals = [];
    return {
      name: 'assert-local-variable-shadowing-helper-invocation',
      visitor: {
        Program: {
          enter: function (node) {
            locals.push(node.blockParams);
          },
          exit: function () {
            locals.pop();
          }
        },
        ElementNode: {
          keys: {
            children: {
              enter: function (node) {
                locals.push(node.blockParams);
              },
              exit: function () {
                locals.pop();
              }
            }
          }
        },
        MustacheStatement: function (node) {
          if (isPath(node.path) && hasArguments(node)) {
            var name = node.path.parts[0];
            var type = 'helper';
            true && !!isLocalVariable(node.path, locals) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, locals));
          }
        },
        SubExpression: function (node) {
          var name = node.path.parts[0];
          var type = 'helper';
          true && !!isLocalVariable(node.path, locals) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, locals));
        },
        ElementModifierStatement: function (node) {
          var name = node.path.parts[0];
          var type = 'modifier';
          true && !!isLocalVariable(node.path, locals) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, locals));
        }
      }
    };
  }

  function isLocalVariable(node, locals) {
    return !node.this && node.parts.length === 1 && hasLocalVariable(node.parts[0], locals);
  }

  function hasLocalVariable(name, locals) {
    return locals.some(function (names) {
      return names.indexOf(name) !== -1;
    });
  }

  function messageFor(name, type) {
    return "Cannot invoke the `" + name + "` " + type + " because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict.";
  }

  function isPath(node) {
    return node.type === 'PathExpression';
  }

  function hasArguments(node) {
    return node.params.length > 0 || node.hash.pairs.length > 0;
  }
});