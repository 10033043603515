define("wc-calendar/templates/components/date-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6oZToIRP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"date-selector__btn-current\"],[11,\"data-test\",\"current\"],[9],[0,\"\\n  Current\\n\"],[3,\"action\",[[22,0,[]],\"current\"]],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"date-selector__btn-previous\"],[11,\"data-test\",\"previous\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/arrow.svg\"],[11,\"alt\",\"Previous\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"previous\"]],[10],[0,\"\\n\"],[7,\"span\"],[9],[1,[21,\"selected\"],false],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"date-selector__btn-next\"],[11,\"data-test\",\"next\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/arrow.svg\"],[11,\"alt\",\"Next\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"next\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "wc-calendar/templates/components/date-selector.hbs"
    }
  });

  _exports.default = _default;
});