define("wc-calendar/components/date-selector", ["exports", "wc-calendar/templates/components/date-selector"], function (_exports, _dateSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A reusable date selector component that provides functionality to cycle
   * through different date ranges.
   *
   * Example usage:
   *
   * ```
   * <DateSelector
   *   @onNext={{this.onNext}}
   *   @onPrevious={{this.onPrevious}}
   *   @onCurrent={{this.onCurrent}}
   * />
   */
  var _default = Ember.Component.extend({
    layout: _dateSelector.default,
    tagName: 'div',
    classNames: ['date-selector'],
    actions: {
      /**
       * Action to trigger moving to the next date range.
       * Calls the `onNext` action passed in from the parent component.
       */
      next: function next() {
        this.onNext();
      },

      /**
       * Action to trigger moving to the previous date range.
       * Calls the `onPrevious` action passed in from the parent component.
       */
      previous: function previous() {
        this.onPrevious();
      },

      /**
       * Action to reset or navigate to the current date range.
       * Calls the `onCurrent` action passed in from the parent component.
       */
      current: function current() {
        this.onCurrent();
      }
    }
  });

  _exports.default = _default;
});