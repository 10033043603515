enifed("ember-template-compiler/lib/system/initializer", ["require", "ember-template-compiler/lib/system/bootstrap"], function (_require, _bootstrap) {
  "use strict";

  // Globals mode template compiler
  if ((0, _require.has)('@ember/application') && (0, _require.has)('@ember/-internals/browser-environment') && (0, _require.has)('@ember/-internals/glimmer')) {
    // tslint:disable:no-require-imports
    var emberEnv = (0, _require.default)("@ember/-internals/browser-environment");
    var emberGlimmer = (0, _require.default)("@ember/-internals/glimmer");
    var emberApp = (0, _require.default)("@ember/application");
    var Application = emberApp.default;
    var hasTemplate = emberGlimmer.hasTemplate,
        setTemplate = emberGlimmer.setTemplate;
    var hasDOM = emberEnv.hasDOM;
    Application.initializer({
      name: 'domTemplates',
      initialize: function () {
        if (hasDOM) {
          (0, _bootstrap.default)({
            context: document,
            hasTemplate: hasTemplate,
            setTemplate: setTemplate
          });
        }
      }
    });
  }
});