define("workcompass-components/templates/components/wc-kebab-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhXB2w8p",
    "block": "{\"symbols\":[\"dropdown\",\"option\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"renderInPlace\"],[true]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('workcompass-components/templates/components/wc-kebab-menu.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"kebab-menu__trigger\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('workcompass-components/templates/components/wc-kebab-menu.hbs' @ L5:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"kebab-menu\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"kebab-menu__content\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[4,\"unless\",[[22,2,[\"shouldHide\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[12,\"class\",[28,[\"kebab-menu__content-item \",[22,2,[\"class\"]],\"\\n              \",[27,\"if\",[[22,2,[\"disabled\"]],\"not-allowed\"],null]]]],[12,\"title\",[27,\"if\",[[22,2,[\"disabled\"]],[27,\"or\",[[22,2,[\"message\"]],[23,[\"msg\"]]],null]],null]],[9],[0,\"\\n              \"],[7,\"a\"],[12,\"id\",[22,2,[\"id\"]]],[11,\"href\",\"#\"],[9],[0,\"\\n                \"],[1,[22,2,[\"label\"]],false],[0,\"\\n              \"],[10],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"handleAction\",[22,2,[]],[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-kebab-menu.hbs"
    }
  });

  _exports.default = _default;
});