define("organization-settings/components/section-template", ["exports", "organization-settings/templates/components/section-template", "ember-autosave", "ember-inflector", "organization-settings/components/save-with-notification"], function (_exports, _sectionTemplate, _emberAutosave, _emberInflector, _saveWithNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _saveWithNotification.default.extend({
    layout: _sectionTemplate.default,
    notifier: Ember.inject.service(),
    isEditing: false,
    hasRatings: Ember.computed.notEmpty('sectionTemplate.displayableRatingOptionTemplates'),
    showRatingOptionsModal: false,
    ratingOptionsModalTitle: Ember.computed('sectionTemplate.draftName', 'sectionTemplateProxy.draftName', function () {
      var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      var name = inflector.pluralize(this.get('sectionTemplate.draftName'));
      return "Edit ".concat(name, " rating options");
    }),
    errorWeight: Ember.computed.or('errorRangeWeight', 'errorTotalWeight'),
    errorRangeWeight: Ember.computed('sectionTemplate.draftWeight', function () {
      var draftWeight = this.get('sectionTemplate.draftWeight');
      return draftWeight < 0 || draftWeight > 100;
    }),
    itemTemplateOrdinals: Ember.computed.mapBy('sectionTemplate.itemTemplates', 'draftOrdinal'),
    maxItemTemplateOrdinal: Ember.computed('sectionTemplate.itemTemplates', function () {
      if (this.get('sectionTemplate.itemTemplates.length') == 0) {
        return 0;
      } else {
        return Math.max.apply(Math, _toConsumableArray(this.get('itemTemplateOrdinals')));
      }
    }),
    draftNameForDisplay: Ember.computed('sectionTemplate.draftName', 'sectionTemplateProxy.draftName', {
      get: function get() {
        var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
        var name = inflector.pluralize(this.get('sectionTemplate.draftName'));

        if (name) {
          name = name[0].toUpperCase() + name.slice(1);
        }

        return name;
      },
      set: function set(key, value) {
        var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
        var name = inflector.singularize(value);

        if (name !== name.toUpperCase()) {
          name = name.toLowerCase();
        }

        this.set('sectionTemplateProxy.draftName', name);
        return value;
      }
    }),
    setIsEditing: function setIsEditing() {
      this.set('isEditing', true);
    },
    sectionTemplateProxy: (0, _emberAutosave.default)('sectionTemplate', {
      save: function save(model) {
        this.saveProxy(model);
      }
    }),

    /**
     * Determines if we can archive the section.
     * 
     * @return {Boolean}
     */
    canArchive: Ember.computed('canRemovePotential', 'sectionTemplate.draftContributesToPotential', function () {
      return this.sectionTemplate.isRateable && this.sectionTemplate.draftContributesToPotential ? this.canRemovePotential : true;
    }),

    /**
     * Determines if we can remove ratings from the section.
     * 
     * @return {Boolean}
     */
    canRemoveRatings: Ember.computed('sectionTemplate.draftContributesToPotential', function () {
      return !this.sectionTemplate.draftContributesToPotential;
    }),
    actions: {
      selectText: function selectText() {
        this.setIsEditing();
        this.$('input.wc-header').select();
      },
      focusOut: function focusOut() {
        this.set('isEditing', false);
      },
      addNewQuestion: function addNewQuestion() {
        var section = this.get('sectionTemplate');
        var question = this.get('sectionTemplate.store').createRecord('questionTemplate', {
          publishedText: '',
          draftOrdinal: this.get('maxItemTemplateOrdinal') + 1
        });
        section.get('itemTemplates').pushObject(question);
      },
      deleteSection: function deleteSection() {
        this.set('sectionTemplateProxy.markedForArchivalAt', new Date());
      },
      addRatings: function addRatings() {
        var template = this.get('sectionTemplate');
        var noRating = this.get('sectionTemplate.store').createRecord('ratingOptionTemplate', {
          draftLabel: 'Not yet rated'
        });
        var zeroRating = this.get('sectionTemplate.store').createRecord('ratingOptionTemplate', {
          draftScore: 0
        });
        template.get('ratingOptionTemplates').pushObjects([noRating, zeroRating]).then(function () {
          noRating.save();
          zeroRating.save();
        });
        this.send('setProperties');
      },
      editRatingOptions: function editRatingOptions() {
        this.set('showRatingOptionsModal', true);
      },
      removeRatings: function removeRatings() {
        var _this = this;

        this.get('notifier').startedSave();
        var promises = [];
        this.send('unsetDraftWeight');
        this.get('sectionTemplateProxy.ratingOptionTemplates').forEach(function (ratOpt) {
          ratOpt.set('markedForArchivalAt', new Date());
          promises.push(ratOpt.save());
        });
        Promise.all(promises).then(function () {
          return _this.get('notifier').finishedSave();
        });
      },
      unsetDraftWeight: function unsetDraftWeight() {
        this.set('sectionTemplateProxy.draftWeight', null);
      },
      setProperties: function setProperties() {
        this.set('sectionTemplateProxy.draftWeight', 0);
        this.set('showRatingOptionsModal', true);
      },

      /**
       * Marks the section template as contributing to potential by setting the
       * 'draftContributesToPotential' flag to true. If no ratings exist, it triggers
       * the 'addRatings' action to prompt the user to add ratings.
       *
       */
      addContributesToPotential: function addContributesToPotential() {
        this.set('sectionTemplateProxy.draftContributesToPotential', true);
        if (!this.hasRatings) this.send('addRatings');
      },
      removeContributesToPotential: function removeContributesToPotential() {
        this.set('sectionTemplateProxy.draftContributesToPotential', false);
      }
    }
  });

  _exports.default = _default;
});