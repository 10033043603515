define("wc-calendar/templates/components/cycle-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QzCMTHCN",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"calendar\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"cycle-calendar__header\"],[9],[0,\"\\n    \"],[5,\"date-selector\",[],[[\"@onNext\",\"@onPrevious\",\"@onCurrent\"],[[27,\"action\",[[22,0,[]],\"next\"],null],[27,\"action\",[[22,0,[]],\"previous\"],null],[27,\"action\",[[22,0,[]],\"current\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"cycleMonths\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"cycle-calendar__month\"],[12,\"data-test\",[28,[\"cycle-calendar-month-\",[22,1,[\"dataTest\"]]]]],[9],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "wc-calendar/templates/components/cycle-calendar.hbs"
    }
  });

  _exports.default = _default;
});