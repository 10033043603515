enifed("ember-template-compiler/lib/system/compile-options", ["exports", "@ember/polyfills", "ember-template-compiler/lib/plugins/index", "ember-template-compiler/lib/system/dasherize-component-name"], function (_exports, _polyfills, _index, _dasherizeComponentName) {
  "use strict";

  _exports.default = compileOptions;
  _exports.registerPlugin = registerPlugin;
  _exports.unregisterPlugin = unregisterPlugin;
  var USER_PLUGINS = [];

  function compileOptions(_options) {
    var options = (0, _polyfills.assign)({
      meta: {}
    }, _options, {
      customizeComponentName: function (tagname) {
        return _dasherizeComponentName.default.get(tagname);
      }
    }); // move `moduleName` into `meta` property

    if (options.moduleName) {
      var meta = options.meta;
      meta.moduleName = options.moduleName;
    }

    if (!options.plugins) {
      options.plugins = {
        ast: [].concat(USER_PLUGINS, _index.default)
      };
    } else {
      var potententialPugins = [].concat(USER_PLUGINS, _index.default);
      var providedPlugins = options.plugins.ast.map(function (plugin) {
        return wrapLegacyPluginIfNeeded(plugin);
      });
      var pluginsToAdd = potententialPugins.filter(function (plugin) {
        return options.plugins.ast.indexOf(plugin) === -1;
      });
      options.plugins.ast = providedPlugins.concat(pluginsToAdd);
    }

    return options;
  }

  function wrapLegacyPluginIfNeeded(_plugin) {
    var plugin = _plugin;

    if (_plugin.prototype && _plugin.prototype.transform) {
      var pluginFunc = function (env) {
        var pluginInstantiated = false;
        return {
          name: _plugin.constructor && _plugin.constructor.name,
          visitor: {
            Program: function (node) {
              if (!pluginInstantiated) {
                pluginInstantiated = true;

                var _plugin2 = new _plugin(env);

                _plugin2.syntax = env.syntax;
                return _plugin2.transform(node);
              }
            }
          }
        };
      };

      pluginFunc.__raw = _plugin;
      plugin = pluginFunc;
    }

    return plugin;
  }

  function registerPlugin(type, _plugin) {
    if (type !== 'ast') {
      throw new Error("Attempting to register " + _plugin + " as \"" + type + "\" which is not a valid Glimmer plugin type.");
    }

    for (var i = 0; i < USER_PLUGINS.length; i++) {
      var PLUGIN = USER_PLUGINS[i];

      if (PLUGIN === _plugin || PLUGIN.__raw === _plugin) {
        return;
      }
    }

    var plugin = wrapLegacyPluginIfNeeded(_plugin);
    USER_PLUGINS = [plugin].concat(USER_PLUGINS);
  }

  function unregisterPlugin(type, PluginClass) {
    if (type !== 'ast') {
      throw new Error("Attempting to unregister " + PluginClass + " as \"" + type + "\" which is not a valid Glimmer plugin type.");
    }

    USER_PLUGINS = USER_PLUGINS.filter(function (plugin) {
      return plugin !== PluginClass && plugin.__raw !== PluginClass;
    });
  }
});