define("workcompass-components/components/wc-kebab-menu", ["exports", "workcompass-components/templates/components/wc-kebab-menu"], function (_exports, _wcKebabMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcKebabMenu.default,

    /*
     * Custom message for unavailable options should be defined.
     * The following is a fallback message if none is provided
     */
    msg: 'Option is currently unavailable.',
    options: [],
    actions: {
      /**
       * Handles the action triggered by a menu option.
       *
       * @param {Object} option - The menu option that was clicked.
       * @param {Object} dropdown - The dropdown context for the action.
       */
      handleAction: function handleAction(option, dropdown) {
        if (option.disabled || !option.action) return;
        option.action(dropdown);
      }
    }
  });

  _exports.default = _default;
});