define("wc-calendar/components/cycle-calendar", ["exports", "wc-calendar/templates/components/cycle-calendar"], function (_exports, _cycleCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cycleCalendar.default,
    attributeBindings: ['dataTest:data-test'],
    classNameBindings: ['typeClassName'],
    classNames: ['cycle-calendar'],
    dataTest: 'cycle-calendar',
    locale: 'en-US',
    selectedCycle: new Date().getFullYear(),
    type: 'calendar',

    /*
     * A computed property that generates an array of month-year pairs from the 
     * given start date to the end date, inclusive.
     */
    cycleMonths: Ember.computed('selectedCycle', 'startDate', 'endDate', 'locale', function () {
      var selectedCycle = this.selectedCycle,
          startDate = this.startDate,
          endDate = this.endDate,
          locale = this.locale;
      var activeCycleStart = new Date(startDate);
      var activeCycleEnd = new Date(endDate);
      var result = [];
      var yearDifference = selectedCycle - activeCycleStart.getFullYear();
      activeCycleStart.setFullYear(activeCycleStart.getFullYear() + yearDifference);
      activeCycleEnd.setFullYear(activeCycleEnd.getFullYear() + yearDifference);

      while (activeCycleStart <= activeCycleEnd) {
        result.push({
          name: activeCycleStart.toLocaleString(locale, {
            month: 'long',
            year: 'numeric'
          }),
          dataTest: activeCycleStart.toLocaleString('en-US', {
            month: 'short',
            year: 'numeric'
          }).toLowerCase().replace(/ /g, '-')
        });
        activeCycleStart.setMonth(activeCycleStart.getMonth() + 1);
      }

      return result;
    }),

    /**
     * Computed property that maps the type to a specific class name.
     */
    typeClassName: Ember.computed('type', function () {
      var typeClassMap = {
        'timeline': 'cycle-calendar--timeline'
      };
      return typeClassMap[this.type] || '';
    }),
    actions: {
      /**
       * Action to shift calendar to the next cycle
       */
      next: function next() {
        this.set('selectedCycle', this.selectedCycle + 1);
      },

      /**
       * Action to shift calendar to the previous cycle
       */
      previous: function previous() {
        this.set('selectedCycle', this.selectedCycle - 1);
      },

      /**
       * Action to return calendar to the current cycle
       */
      current: function current() {
        this.set('selectedCycle', new Date().getFullYear());
      }
    }
  });

  _exports.default = _default;
});