define("frontend/helpers/split-string", ["exports", "workcompass-components/helpers/split-string"], function (_exports, _splitString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _splitString.default;
    }
  });
  Object.defineProperty(_exports, "splitString", {
    enumerable: true,
    get: function get() {
      return _splitString.splitString;
    }
  });
});
